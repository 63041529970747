export const pageSizeMap = {
  a4: {
    width: 210,
    height: 297,
  },
  letter: {
    width: 216,
    height: 279,
  },
} as const;
